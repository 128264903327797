import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import withRoot from '../../withRoot'
import Page from '../../components/page'
import Table from '../../components/table'
import { WarningNote } from '../../components/highlight'

import {
  Typography,
  List,
  ListItemText,
} from '@material-ui/core'

const createData = (tag, description, usage) => { return [tag, description, usage] }

const rowsOperator = [
  createData('+', 'Addition', '(#ts# + 12)'),
  createData('-', 'Subtraction', '(#ts# - 12)'),
  createData('*', 'Multiplication', '(#ts# * 12)'),
  createData('/', 'Division', '(#ts# / 12)'),
  createData('%', 'Modulo', '(#ts# % 12)'),
]

const rowsFunction = [
  createData('cos', 'Cosine (works in degrees)', '(cos(#trss#))'),
  createData('sin', 'Sine (works in degrees)', '(sin(#trss#))'),
  createData('tan', 'Tangent (works in degrees)', '(tan(#trss#))'),
  createData('rand', 'Random number generator (between 0 and 1)', '(rand() * 9)'),
  createData('abs', 'Absolute value', '(abs(-5))'),
  createData('round', 'Round (the closest integer)', '(round(9.8))'),
  createData('ceil', 'Ceiling (the next highest integer)', '(ceil(9.8))'),
  createData('floor', 'Floor (the next lowest integer)', '(floor(9.8))'),
  createData('chr', 'Get the character from the corresponding ASCII value', '(chr(65))'),
]

const rowsCondition = [
  createData('==', 'Is equal to', '( #th# == 3 ? A : B )'),
  createData('!=', 'Is not equal to', '( #th# != 3 ? A : B )'),
  createData('>', 'Is greater than', '( #th# > 3 ? A : B )'),
  createData('<', 'Is less than', '( #th# < 3 ? A : B )'),
  createData('>=', 'Is greater than or equal to', '( #th# >= 3 ? A : B )'),
  createData('<=', 'Is less than or equal to', '( 1 <= 3 ? A : B )'),
  createData('&&', 'AND operator', '( (#th# <= 3 && #th# > 0) ? A : B )'),
  createData('||', 'OR operator', '( (#th# <= 3 || #th# > 6) ? A : B )'),
]

const TABLES = [
  { title: 'Operators', tags: rowsOperator },
  { title: 'Functions', tags: rowsFunction },
]

const styles = theme => ({
  title: {
    marginTop: theme.spacing(6),
  },
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  bold: {
    fontWeight: 'bold',
    fontStyle: 'italic'
  }
})

@withRoot
@withStyles(styles)
export default class Expressions extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Expressions">
        <Typography>
          You loved the tags but you want more.<br />
          Here come the math functions and other helpers you may find useful.
        </Typography>
        <WarningNote>
          <Typography>
            Expressions must be surrounded by parentheses to be evaluated, e.g.
          </Typography>
          <Typography className={classes.bold}>
            ( cos( #trs# ) * 130 )
          </Typography>
        </WarningNote>
        {TABLES.map((e, i) => (
          <Table key={i} title={e.title} head={['Tag', 'Description', 'Usage']} content={e.tags} />
        ))}
        <Typography align='center' variant="h3" component="h3" className={classes.title}>
          Conditions
        </Typography>
        <Typography className={classes.parag}>
          We know you needed even more to make highly dynamic designs.<br />
          The conditions are here for you!
        </Typography>
        <Typography className={classes.parag}>
          You can write your expression like this: ( COND ? A : B )
        </Typography>
        <Typography className={classes.parag}>
          This means, if the condition COND is true then the value is A, else it&apos;s B.
        </Typography>
        <br />
        <Typography className={classes.parag}>
          You can use the following boolean operators in your test:<br />
        </Typography>
        <Table head={['Tag', 'Description', 'Usage']} content={rowsCondition} />
        <Typography variant="h4" component="h4" className={classes.title}>
          Examples
        </Typography>
        <List>
          <ListItemText>( #ts# % 2 == 0 ? 0 : 1 ): this expression will return 0 on even seconds.</ListItemText>
          <ListItemText>( #th23# &gt; 11 ? &ldquo;pm&ldquo; : &ldquo;am&ldquo; ): this expression will return &ldquo;pm&ldquo; if the hours are greater than 11.</ListItemText>
        </List>
      </Page>
    )
  }
}
